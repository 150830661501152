/* OfferGrid.css */

.offer-grid-container {
  padding: 50px 30px;
  text-align: center;
  background-color: #F4F4F9;
}

.offer-grid-container h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  color: #1C3A57;
  margin-bottom: 40px;
}

.offer-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive */
  gap: 30px;
  justify-content: center; /* Centre la grille */
}

.offer-grid-item {
  background-color: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.offer-grid-item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.15);
}

.offer-grid-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.offer-grid-item h3 {
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  margin: 0;
  transition: color 0.3s ease;
}

.offer-grid-item:hover h3 {
  color: #007AFF;
}
