/* Centrer et positionner la barre de recherche */
.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 80px;
  position: relative;
}

.search-bar {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 700px;
  width: 90%;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50px;
  padding: 15px 25px;
  backdrop-filter: blur(15px);
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.15);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.search-bar:focus-within {
  transform: scale(1.05);
  box-shadow: 0 15px 45px rgba(0, 0, 0, 0.25);
}

.search-bar input {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 50px;
  background-color: transparent;
  outline: none;
  font-size: 18px;
  color: #333;
  transition: background-color 0.3s;
}

.search-bar input:focus {
  background-color: rgba(255, 255, 255, 0.6);
}

.search-bar .icon.location-icon {
  margin-right: 15px;
  color: #4A90E2;
  font-size: 22px;
}

.search-bar .search-button {
  background: linear-gradient(135deg, #4A90E2, #357ABD);
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, background-color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.search-bar .search-button:hover {
  transform: scale(1.1);
  background: linear-gradient(135deg, #357ABD, #4A90E2);
}

/* Suggestions dropdown */
.suggestions {
  position: absolute;
  top: 80px;
  left: 50% !important;
  transform: translateX(-50%);
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.95);
  list-style-type: none;
  padding: 0;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-width: 1250px;
  width: 90%;
  overflow: hidden;
  animation: fadeInZoom 0.4s ease;
}

@keyframes fadeInZoom {
  from {
    opacity: 0;
    transform: translateY(-15px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.suggestions li {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  color: #333;
  transition: background-color 0.3s;
}

.suggestions li:hover {
  background-color: #f0f5ff;
}

.suggestions .icon.suggestion-icon {
  margin-right: 12px;
  color: #4A90E2;
  font-size: 18px;
}

/* Responsive design for mobile */
@media (max-width: 768px) {
  .search-bar {
    max-width: 100%;
    padding: 12px;
  }

  .suggestions {
    max-width: 100%;
  }
}
