@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --radius: 0.5rem;
  }

}

/* Dans votre fichier CSS global ou App.css */
.main-content {
  margin-top: 5rem; /* 4rem (hauteur du header) + 1rem d'espace */
}

/* Pour les pages avec un titre de bienvenue */
.welcome-section {
  margin-top:50px;
}

h1, h2, h3, h4, h5, h6{
  font-weight: 600;
}

.bg-main{
  background-color: #f4f4f9;
}

.with-60 {
  width: 60%;
  min-width: 450px;
}
.height-70{
  height: 70vh;
}

@media (max-width: 425px) {
  .with-60 {
    width: 100%;
    min-width: 0;
  }
}



/* Si les bouton sont disabled  ajout icone du curseur  */
.disabled {
  cursor: not-allowed;
}

/* src/index.css or App.css */

/* Conteneur principal qui prend toute la hauteur */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 85vh;
}

/* Contenu principal qui s'étend pour remplir l'espace restant */
.main-content {
  flex: 1;
}

/* Styles de base pour le footer */
footer {
  background-color: #004680; /* Couleur du footer */
  color: white;
  text-align: center;
  padding: 20px;
}
