/* Testimonials.css */

.testimonials {
  padding: 80px 30px; /* Plus d'espace pour un design plus aéré */
  text-align: center;
  background-color: white;
}

.testimonials h2 {
  font-family: 'Poppins', sans-serif; /* Police moderne */
  font-size: 36px; /* Taille de police augmentée pour un meilleur impact visuel */
  font-weight: 600; /* Titre en gras pour une meilleure lisibilité */
  margin-bottom: 40px;
  color: #004680; /* Bleu foncé */
}

.testimonial-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px; /* Ajout de gap pour espacer les cartes uniformément */
}

.testimonial-card {
  background-color: #F9F9F9;  /* Gris clair */
  padding: 30px;
  border-radius: 15px; /* Bords plus arrondis pour un look moderne */
  width: 45%;
  margin: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1); /* Ombre plus marquée pour plus de profondeur */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transition douce pour hover */
}

.testimonial-card:hover {
  transform: translateY(-5px); /* Léger mouvement vers le haut au hover */
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.15); /* Ombre plus profonde au hover */
}

.testimonial-card p {
  font-family: 'Poppins', sans-serif; /* Police moderne */
  font-size: 16px;
  font-style: italic;
  color: #666;
  line-height: 1.8; /* Meilleure lisibilité du texte */
}

.testimonial-card h4 {
  font-family: 'Poppins', sans-serif; /* Police moderne */
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500; /* Poids intermédiaire pour le nom de l'auteur */
  color: #004680; /* Bleu foncé */
}

/* Responsive for mobile */
@media (max-width: 768px) {
  .testimonial-cards {
    flex-direction: column;
    align-items: center;
  }

  .testimonial-card {
    width: 80%;
  }

  .testimonials h2 {
    font-size: 28px; /* Taille de police réduite pour mobile */
  }
}
