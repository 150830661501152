/* Home.css */

/* Global styles for the home page */
body {
    font-family: 'Arial', sans-serif;
    background-color: #F9F9F9;  /* Gris clair */
    margin: 0;
    padding: 0;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Arial', sans-serif;
    color: #004680;
  }
  
  p {
    font-size: 16px;
    color: #666;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  button {
    font-family: 'Arial', sans-serif;
    cursor: pointer;
  }
  
  input {
    font-family: 'Arial', sans-serif;
  }
  
  /* Example of reusable utility classes */
  .text-center {
    text-align: center;
  }
  
  .mt-20 {
    margin-top: 20px;
  }
  
  .mb-20 {
    margin-bottom: 20px;
  }
  
  /* Override browser default styles for consistency */
  * {
    box-sizing: border-box;
  }
  
  