/* Discover.css */

/* Structure générale */
.discover-page {
  padding: 40px 20px;
  text-align: center;
  background-color: #f4f4f9; /* Fond gris clair */
}

.discover-page h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  color: #333;
  margin-bottom: 40px;
}

.discover-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 colonnes sur les grands écrans */
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.discover-grid-item {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  padding-bottom: 20px; /* Ajout de padding en bas pour créer de l'espace autour du bouton */
}

.discover-grid-item:hover {
  transform: scale(1.05); /* Léger zoom au hover */
}

.discover-grid-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.discover-grid-item h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  color: #0072CE;
  padding: 15px;
  margin-bottom: 15px; /* Ajouter une marge sous le titre pour espacer le bouton */
}

/* Styles pour le bouton */
.discover-grid-item .discover-button {
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  background-color: #0072CE;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  text-decoration: none; /* Supprimer la sous-ligne des liens */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.discover-grid-item .discover-button:hover {
  background-color: #005BBB;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .discover-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 colonnes sur les écrans larges mais plus petits */
  }
}

@media (max-width: 768px) {
  .discover-page h1 {
    font-size: 28px; /* Taille de police réduite sur mobile */
  }

  .discover-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 colonnes sur les tablettes */
  }

  .discover-grid-item img {
    height: 180px; /* Ajustement de la hauteur des images sur tablette */
  }

  .discover-grid-item .discover-button {
    font-size: 14px; /* Réduction de la taille du bouton sur tablette */
    padding: 8px 16px;
  }
}

@media (max-width: 576px) {
  .discover-page h1 {
    font-size: 24px; /* Taille de police encore réduite pour les petits écrans */
  }

  .discover-grid {
    grid-template-columns: 1fr; /* 1 colonne sur les petits écrans (mobile) */
  }

  .discover-grid-item img {
    height: 160px; /* Ajustement de la hauteur des images sur mobile */
  }

  .discover-grid-item .discover-button {
    font-size: 14px; /* Réduction de la taille du bouton sur mobile */
    padding: 8px 16px;
  }
}
